import axios from 'axios'
let baseURL = '/api'
import { message } from 'ant-design-vue'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { router } from '@/router/index'
const service = axios.create({
    withCredentials: true,
    crossDomain: true,
    baseURL,
    timeout: 6000
})

const whiteUrl = [
    '/admin/auth/captcha',
    '/admin/auth/login'
  ]

// request拦截器,在请求之前做一些处理
service.interceptors.request.use(
    config => {
        if (! (whiteUrl.indexOf(config.url) > -1)) {
            config.headers['Authorization'] = getToken()
          }
        return config;
    },
    error => {
        console.log(error); // for debug
        return Promise.reject(error);
    }
);

//配置成功后的拦截器
service.interceptors.response.use(async res => {
    console.log(res, '@@@@@----------------');
    if (res.status == 200) {
        let { code } = res.data
        if (code == '0') return res.data.data
        // #ifndef H5
        else if (code == '1100002') {
            removeToken()
            router.push('/login')
            return Promise.reject(res)
        } else if (code == 10005) {
            // let token = await refreshLogin()
            // res.config.headers.Authorization = token
            router.replace('/login')
        } else {
            return Promise.reject(res)
        }
    } else {
        return Promise.reject(res);
    }
}, error => {
    return Promise.reject(error)
})


async function http (praram) {
    try {
        let res = await service(praram)
        return res
    } catch (error) {
        message.error(error.data.message || '系统异常')
        return Promise.reject()
    }
}

export {
    http,
    service
}