// 常量路由
export const constantRoutes = [
  {
    path: '/',
    redirect: '/login',
    meta: {
      hidden: true
    }
  },
  {
    path: '/login',
    meta: {
      hidden: true
    },
    component: () => import('@/views/login/index')
  },
  {
    path: '/home',
    name: 'Home',
    meta: {
      icon: 'el-icon-s-home',
      name: '首页'
    },
    component: () => import('../views/home/index.vue')
  },
]

// 任意路由
export const anyRoutes = [
    //
]

// 动态路由
export const asyncRoutes = () => [
  {
    path: '/customer',
    name: 'Customer',
    component: () => import('@/views/customer/index.vue'),
    meta: {
      icon: 'el-icon-s-custom',
      name: '客户管理'
    },
    redirect: '/customer/list',
    children: [
      {
        path: '/customer/list',
        name: 'CustomerList',
        meta: {
          name: '客户列表',
          icon:''
        },
        component: () => import('@/views/customer/list.vue')
      },
      // {
      //   path: '/customer/addRemark/:memberId',
      //   name: 'CustomerAddRemark',
      //   component: () => import('@/views/customer/addRemark.vue'),
      //   meta: { name: '添加备注', hidden: true }
      // },
      {
        path: '/customer/operationLog/:memberId?',
        name: 'CustomerRemarkList',
        component: () => import('@/views/customer/operationLog.vue'),
        meta: { name: '备注列表', hidden: true }
      },
      {
        path: '/customer/order/list/:id?',
        name: 'CustomerOrderList',
        component: () => import('@/views/customer/detail.vue'),
        meta: { name: '订单列表', hidden: true }
      },
    ]
  },
  {
    path: '/order',
    name: 'Order',
    redirect: '/order/list',
    component: () => import('@/views/order/index.vue'),
    meta: { name: '订单管理', icon: 'el-icon-s-order' },
    children: [
      {
        path: '/order/list/',
        name: 'OrderList',
        component: () => import('@/views/order/list.vue'),
        meta: { name: '订单列表' }
      },
      // {
      //   path: '/order/addEdit/:id?',
      //   name: 'OrderAddEdit',
      //   component: () => import('@/views/order/addEdit.vue'),
      //   meta: { name: '新增、编辑', hidden: true }
      // },
      {
        path: '/order/detail/:id?',
        name: 'OrderDetail',
        component: () => import('@/views/order/detail.vue'),
        meta: { name: '账单详情', hidden: true }
      }
    ]
  },
  {
    path: '/bill',
    name: 'Bill',
    redirect: '/bill/list',
    component: () => import('@/views/bill/index.vue'),
    meta: { name: '账单管理', icon: 'el-icon-coin' },
    children: [
      {
        path: '/bill/list/',
        name: 'BillList',
        component: () => import('@/views/bill/list.vue'),
        meta: { name: '账单列表' }
      },
      {
        path: '/bill/addEdit/:id?',
        name: 'BillAddEdit',
        component: () => import('@/views/bill/addEdit.vue'),
        meta: { name: '新增、编辑', hidden: true }
      }
    ]
  },
  {
    path: '/finance',
    name: 'Finance',
    redirect: '/finance/list',
    component: () => import('@/views/finance/index.vue'),
    meta: { name: '财务管理', icon: 'el-icon-coin' },
    children: [
      {
        path: '/finance/list/',
        name: 'FinanceList',
        component: () => import('@/views/finance/list.vue'),
        meta: { name: '财务明细' }
      }
    ]
  },
  {
    path: '/powerManage',
    name: 'PowerManage',
    redirect: '/powerManage/user/list',
    component: () => import('@/views/powerManage/index.vue'),
    meta: { name: '权限管理', icon: 'el-icon-s-check' },
    children: [
      {
        path: '/powerManage/user/list',
        name: 'UserList',
        component: () => import('@/views/powerManage/user/list.vue'),
        meta: { name: '管理员' }
      },
      {
        path: '/powerManage/user/addEdit',
        name: 'UserAdd',
        component: () => import('@/views/powerManage/user/addEdit.vue'),
        meta: { name: '新增', hidden: true }
      },
      {
        path: '/powerManage/user/addEdit/:id?',
        name: 'UserEdit',
        component: () => import('@/views/powerManage/user/addEdit.vue'),
        meta: { name: '编辑', hidden: true }
      },
      {
        path: '/powerManage/role/list',
        name: 'RoleList',
        component: () => import('@/views/powerManage/role/list.vue'),
        meta: { name: '角色管理' }
      },
      {
        path: '/powerManage/role/addEdit',
        name: 'RoleAdd',
        component: () => import('@/views/powerManage/role/addEdit.vue'),
        meta: { name: '新增', hidden: true }
      },
      {
        path: '/powerManage/role/addEdit/:id?',
        name: 'RoleEdit',
        component: () => import('@/views/powerManage/role/addEdit.vue'),
        meta: { name: '编辑', hidden: true }
      },
      {
        path: '/powerManage/menu/list',
        name: 'MenuList',
        component: () => import('@/views/powerManage/menu/list.vue'),
        meta: { name: '菜单管理' }
      },
      {
        path: '/powerManage/menu/addEdit/:menuId?/:parentId?',
        name: 'MenuAdd',
        component: () => import('@/views/powerManage/menu/addEdit.vue'),
        meta: { name: '新增', hidden: true }
      },
      {
        path: '/powerManage/menu/addEdit/:menuId?/:parentId?',
        name: 'MenuEdit',
        component: () => import('@/views/powerManage/menu/addEdit.vue'),
        meta: { name: '编辑', hidden: true }
      }
    ]
  },
  {
    path: '/systemManage',
    name: 'SystemManage',
    redirect: '/systemManage/dictManage/list',
    component: () => import('@/views/systemManage/index.vue'),
    meta: { name: '系统设置', icon: 'el-icon-setting' },
    children: [
      {
        path: '/systemManage/dictManage/list',
        name: 'DictManageList',
        component: () => import('@/views/systemManage/dictManage/index.vue'),
        meta: { name: '字典管理' }
      },
      {
        path: '/systemManage/dictManage/addOrEdit',
        name: 'dictManageAddOrEdit',
        component: () => import('@/views/systemManage/dictManage/detail-dict.vue'),
        meta: { hidden: true, name: '字典管理' }
      },
      {
        path: '/systemManage/dictValueManage',
        name: 'DictValueManage',
        component: () => import('@/views/systemManage/dictManage/list-dict-value.vue'),
        meta: { hidden: true, name: '字典值管理' }
      },
      {
        path: '/systemManage/dictValueManage/addOrEdit',
        name: 'DictValueAddOrEdit',
        component: () => import('@/views/systemManage/dictManage/detail-dict-value.vue'),
        meta: { hidden: true, name: '字典值管理' }
      },
      {
        path: '/systemManage/dataSync/list',
        name: 'DataSyncList',
        component: () => import('@/views/systemManage/dataSync/list.vue'),
        meta: { name: '数据同步' }
      },
      {
        path: '/systemManage/logManage/list',
        name: 'LogManageList',
        component: () => import('@/views/systemManage/logManage/list.vue'),
        meta: { name: '操作日志' }
      }
    ]
  }
]
