<template functional>
  <div class="title">
    {{ props.title }}
  </div>
</template>

<script>
export default {
  name: 'AdminTitle',
}
</script>

<style lang="scss" scoped>

.title {
  position: relative;
  padding: 0 10px;
  color: #000;
  font-weight: bold;
  font-size: 16px;
}

.title::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0px;
  margin-top: -6px;
  width: 4px;
  height: 12px;
  background-color: #00aeff;
}

</style>