<template>
  <div id="app">
    <a-layout v-if="!isLoginPage">
      <a-layout-sider v-model="collapsed" :trigger="null" collapsible>
        <left-menu></left-menu>
      </a-layout-sider>
      <a-layout>
        <a-layout-header style="background: #fff; padding: 0">
          <top-menu v-model="collapsed"></top-menu>
        </a-layout-header>
        <a-layout>
          <a-layout-sider
            :style="{ marginBottom: '24px', background: '#fff', paddingTop: '24px', maxWidth: '140px', minWidth: '0', flex: 'unset', width: 'auto' }"
          >
            <!-- <left-second-menu></left-second-menu> -->
          </a-layout-sider>
          <a-layout-content :style="{ margin: '24px 16px', padding: '24px', background: '#fff', display: 'flex', width: '800px' }">
            <!-- <keep-alive> -->
              <router-view></router-view>
            <!-- </keep-alive> -->
          </a-layout-content>
        </a-layout>
      </a-layout>
    </a-layout>
    <router-view v-else></router-view>
  </div>
</template>
<script>
import LeftMenu from './components/left-menu'
import TopMenu from './components/top-menu'
import LeftSecondMenu from './components/left-second-menu'
export default {
  data() {
    return {
      collapsed: false
    }
  },

  computed: {
    isLoginPage() {
      return this.$route.path === '/login'
    }
  },

  components: {
    LeftMenu,
    TopMenu,
    LeftSecondMenu
  },

  methods: {}
}
</script>
<style lang="scss" scoped>
.ant-layout {
  height: 100vh;
  overflow: hidden;
}

.ant-layout-content {
  overflow-y: auto;
}

.container {
  display: flex;
}
</style>
