import Vue from 'vue'
import VueRouter from 'vue-router'
import { constantRoutes, asyncRoutes } from './routes'

Vue.use(VueRouter)

// 解决重复点击路由报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes: constantRoutes
})

export { router }
