import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import user from './modules/user'
import permission from './modules/permission'
import getters from './getters'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    user,
    permission
  },
  getters,
  plugins: [
    // veux持久化配置
    createPersistedState({
      storage: window.localStorage,
      key: 'rabbitstore-client',
      // paths: ['user']
    })
  ]
})

export default store
