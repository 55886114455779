import Vue from 'vue'
import 'ant-design-vue/dist/antd.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import Antd from 'ant-design-vue'
import '@/styles/index.scss' // global css

// echarts
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
Vue.use(ElementUI, { zIndex: 3000 })
Vue.use(Antd)