<template>
  <div class="left-menu" v-if="menuList.length > 0">
    <a-menu mode="inline" :selectedKeys="defaultSelect">
      <a-menu-item-group>
        <template slot="title">
          <div style="font-weight: bold; color: #000; margin-bottom: 10px;">{{ parentRoute.meta.name }}</div>
        </template>
        <a-menu-item
          v-for="item in menuList"
          :key="item.name"
          @click="goPage(item)"
          style="padding-left: 0; padding-right: 0; text-align: center; height: 30px; line-height: 30px;"
        >
          <span>{{ item.meta && item.meta.name }}</span>
        </a-menu-item>
      </a-menu-item-group>
    </a-menu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'LeftSecondMenu',

  computed: {
    ...mapGetters(['getResultRoutes']),

    menuList() {
      let menu = this.parentRoute.children || []
      return menu.filter(item => {
        return !item.meta.hidden
      })
    },

    parentRoute() {
      const routes = this.getResultRoutes
      let targetPath = this.$route.fullPath
      return routes.find(item => targetPath.indexOf(item.path) > -1 && !item.meta.hidden) || {}
    },

    defaultSelect() {
      return [this.$route.name]
    }
  },

  methods: {
    goPage(item) {
      const { path,name } = item
      this.$router.push({
        name
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.left-menu /deep/ {
  width: 140px;

  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: none;
  }
}
</style>
